<template>
    <section id="main-feature">


        <div class="main-title">
            Features
        </div>

        <div class="list-featured" v-scrollanimation>

            <div class="item-featured">
                <img :src="require('@/assets/images/animated/community_build.svg')" alt="banner-img" class="img-fluid image-icon">
                <div class="break"></div>
                <h3>Transparency and Community-Driven</h3>
                <p>There is no pre-sale. Dev added tokens to LP on
                    PancakeSwap at the Fair Launch.</p>
            </div>

            <div class="item-featured">
                <img :src="require('@/assets/images/animated/pool_liquility.svg')" alt="banner-img"
                     class="img-fluid image-icon">
                <div class="break"></div>
                <h3>Automatic Liquidity Pool</h3>
                <p>4% of every transaction contributes toward automatically generating further liquidity
                    on Pancake Swap. Benefiting long term for $SMRAT holders the most!</p>
            </div>

            <div class="item-featured">
                <img :src="require('@/assets/images/animated/earn-bnb.svg')" alt="banner-img"
                     class="img-fluid image-icon">
                <div class="break"></div>
                <h3>Earn $BNB by hodling $SMRAT</h3>
                <p>4% of every transaction is taken and re-distributed to all MoonRat holders in $BNB.</p>
            </div>

            <div class="item-featured">
                <img :src="require('@/assets/images/animated/anti-whale.svg')" alt="banner-img"
                     class="img-fluid image-icon">
                <div class="break"></div>
                <h3>Anti Pump-Dump-Exit Whales</h3>
                <p> Transactions (sell/buy/transfer) that trade more than 0.01% of the total supply will be rejected.
If whales want to make a transfer (between 2 wallets) that is larger than 0.01% of the total supply, they can use the  Disruptive Transfer feature: the transfer will be charged for 2 BNB (taxed 10%).</p>
            </div>
            <div class="item-featured">
                <img :src="require('@/assets/images/animated/static_reward.svg')" alt="banner-img"
                     class="img-fluid image-icon">
                <div class="break"></div>
                <h3>RFI Static Rewards</h3>
                <p>2% of every transaction is taken and re distributed to all $SMRAT holders.
                <a href="https://bscscan.com/token/0x68590a47578e5060a29fd99654f4556dbfa05d10?a=0x000000000000000000000000000000000000dead" target="_blank">The burn
                    address</a> is also a holder thus each transaction helps deflate the supply.</p>
            </div>
            <div class="item-featured">
                <img :src="require('@/assets/images/animated/security.svg')" alt="banner-img"
                     class="img-fluid image-icon">
                <div class="break"></div>
                <h3>Highly Secured</h3>
                <p>We have burned all the Liquidity Pool tokens (SMRAT/BNB LP) and renounced contract ownership to provide security for the whole community!
                The smart contract is audited by Certik and TechRate</p>
            </div>

        </div>
    </section>
</template>

<script>
    export default {
        name: 'Feature',
        props: {}
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


.before-enter {
  opacity: 0;
  transform: translateY(100px);
  transition: all 1s ease-out;
}

/*
  If the element intersects with the viewport, the before-enter class is added.
*/
.enter {
  opacity: 1;
  transform: translateY(0px);
}

</style>
