import Vue from 'vue';
import Router from 'vue-router';
import Main from './components/Main';
import Branding from "./components/Branding";
import Guide from "./components/Guide";
import GuideMigrate from "./components/GuideMigrate";
import Terms from "@/components/Terms";
import Privacy from "@/components/Privacy";

Vue.use(Router);


export default new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            component: Main,
        }, {
            path: '/branding',
            name: 'branding',
            component: Branding,
        },
        {
            path: '/guide',
            name: 'guide',
            component: Guide,
        },
        {
            path: '/terms',
            name: 'terms',
            component: Terms,
        },
        {
            path: '/privacy',
            name: 'privacy',
            component: Privacy,
        },
        {
            path: '/guide/migrate',
            name: 'guide-migrate',
            component: GuideMigrate,
        },
        { path: '*', beforeEnter: (to, from, next) => { next('/') } },
    ],
    mode: 'history',
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0};
    }
});
