<template>
  <section id="more-info">
    <div class="card-wrapper" >
      <div class="part-1" v-scrollanimation>
        <div class="title-1">
          What is EarnBNB?
        </div>
        <div class="desc-1">
          4% of every transaction is taken and re-distributed to all MOONRAT holders in $BNB. You collect BNB weekly by
          accessing the MoonRat dashboard page
        </div>

        <div class="button-wrapper" v-animate-css.hover="'headShake'">
          <a class="button-custom" href="https://app.moonrat.finance/" target="_blank">
            Launch App
          </a>
        </div>

      </div>

      <div class="part-2" v-scrollanimation>
        <img :src="require('@/assets/images/box_SMRAT_ANIMATED.svg')">
      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: 'MoreInfo',
  props: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.before-enter {
  opacity: 0;
  transform: translateY(100px);
  transition: all 1s ease-out;
}

/*
  If the element intersects with the viewport, the before-enter class is added.
*/
.enter {
  opacity: 1;
  transform: translateY(0px);
}
</style>
