<template>
  <section id="how-to-buy" class="padding-left-0">
  </section>
</template>

<script>
export default {
  name: "Community",
  components: {},
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
