<template>
  <div>
    <footer id="footer">
      <div class="footer-new">
        <div class="row">
          <div class="col-lg-4 col-sm-11">
            <div class="footer-logo">
              <a class="f-logo"><b>MoonRat</b></a>

              <p>MoonRat is a community driven, fair launched DeFi project built on Binance Smart Chain
                #BSC.
                MoonRat brings the most revolutionary mechanism to BSC ecosystem: Earn $BNB just by
                holding $SMRAT token</p>

              <p>
                Support Email: <a href="mailto:support@moonrat.finance" target="_blank">support@moonrat.finance</a>
                <br/>
                Partnership & Inquiry Email: <a href="mailto:hello@moonrat.finance" target="_blank">hello@moonrat.finance</a>
              </p>
            </div>
          </div>
          <div class="col-lg-2"></div>
          <div class="col-lg-3 col-sm-4">
            <div class="links">
              <h3>Important Links</h3>
              <ul>
                <li>
                  <a href="https://www.certik.org/projects/moonratfinance"
                     target="_blank">Certik Audit
                  </a>
                </li>
                <li>
                  <a href="https://docs.moonrat.finance/liquidity-locks"
                     target="_blank">Liquidity Locks
                  </a>
                </li>

                <li>
                  <a href="https://bscscan.com/token/0x68590a47578e5060a29fd99654f4556dbfa05d10"
                     target="_blank">Contract
                  </a>
                </li>
                <li>
                  <a href="https://docs.moonrat.finance" target="_blank">WhitePaper </a>
                </li>

                <li><a href="https://coinmarketcap.com/currencies/secured-moonrat-token/"
                       target="_blank">CMC</a>
                </li>
                <li><a href="https://www.coingecko.com/en/coins/secured-moonrat-token" target="_blank">CoinGecko</a>
                </li>
                <li>
                  <a href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x68590a47578e5060a29fd99654f4556dbfa05d10"
                     target="_blank">PancakeSwap
                  </a>
                </li>
                <li>
                  <a href="https://dex.guru/token/0x68590a47578e5060a29fd99654f4556dbfa05d10-bsc"
                     target="_blank">Dex.Guru
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-sm-4">
            <div class="links">
              <h3>Informations</h3>
              <ul>
                <li>
                  <a href="https://guide.moonrat.finance/" target="_blank">Guide</a>
                </li>
                <li><a href="mailto:hello@moonrat.finance">Contact Us</a></li>
                <li><a href="https://moonrat.finance/terms">Terms of Service</a></li>
                <li><a href="https://moonrat.finance/privacy">Privacy Policy</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <section id="footer-btm">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="fop-btm text-center">
              <h2>
                Copyright @ MoonRat.Finance
              </h2>
              <h2>
                RRR! RRRR!
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="footer-btm">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="fop-btm text-center">
              <h2>
                Disclaimer: The information provided shall not in any way constitute a recommendation as to whether you
                should invest in any product discussed. MoonRat accepts no liability for any loss occurring to any
                person acting or refraining from action as a result of any material provided or published. MoonRat was
                built as an experiment in decentralization.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  methods: {
    commingSoon() {
      this.$swal({
        title: 'Coming Soon ...',
        width: 400,
        padding: '2em',
        background: '#fff url(https://i.ibb.co/gwh2dbd/moon.png)',
        backdrop: `
                                rgba(0, 0, 0, 0.5)
                                url("https://i.ibb.co/qFgF3yZ/moonrat-02.gif")
                                left top
                                no-repeat
                              `
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
