<template>
  <section id="how-to-buy" class="padding-left-0">
    <div class="main-title">Price Tracking</div>

    <div>
      <div class="row" style="margin-left: 0px; margin-right: 0px" v-scrollanimation>
        <div class="list-item-hover">

          <a class="item-hover" href="https://coinmarketcap.com/currencies/secured-moonrat-token/" target="_blank">
            <div class="item-icon">
              <img :src="require('@/assets/images/coinmarketcap.jpeg')" />
            </div>
            <div class="item-content">
              <div class="item-title">CoinMarketCap</div>
              <div class="item-desc">Price tracking in CoinMarketCap</div>
            </div>
          </a>

          <a class="item-hover" href="https://www.coingecko.com/en/coins/secured-moonrat-token" target="_blank">
            <div class="item-icon">
              <img :src="require('@/assets/images/coingecko.png')" />
            </div>
            <div class="item-content">
              <div class="item-title">CoinGecko</div>
              <div class="item-desc">Price tracking in CoinGecko</div>
            </div>
          </a>

         <!-- <a class="item-hover" href="https://blockfolio.canny.io/coin-requests/p/secured-moonrat-token" target="_blank">
            <div class="item-icon">
              <img :src="require('@/assets/images/blogfolio.png')" />
            </div>
            <div class="item-content">
              <div class="item-title">Blockfolio</div>
              <div class="item-desc">Tracking in Blockfolio</div>
            </div>
          </a>
          -->

        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PriceTracking",
  components: {},
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
