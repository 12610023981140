import Web3 from "web3";

const MRAT_CONTRACT_ADDRESS = '0x68590a47578e5060a29fd99654f4556dbfa05d10';
const web3Client = new Web3('https://bsc-dataseed.binance.org/');
import moment from 'moment';

let BNBFeedPrice = null;
let lastTimeSynced = new Date();

const fetchBNBPrice = async () => {
    const nextSyncedTime = lastTimeSynced.getTime() + 3600000;
    if (BNBFeedPrice === null || new Date().getTime() > nextSyncedTime) {
        console.log('next fetch price feed time:', moment(nextSyncedTime).format('llll'));
        const response = await fetch('https://min-api.cryptocompare.com/data/pricemultifull?fsyms=BNB&tsyms=USD')
        BNBFeedPrice = await response.json()
    }

    return BNBFeedPrice;
};

export const MoonRat = {
    address: MRAT_CONTRACT_ADDRESS,
    jsonInterface: require('../assets/contracts/MoonRatToken.json')
}

export const MoonRatLiquidityPool = {
    jsonInterface: require('../assets/contracts/PancakePair.json')
}

export const getMoonRatContract = async () => {
    const accounts = await web3Client.eth.getAccounts()
    return new web3Client.eth.Contract(
        MoonRat.jsonInterface.abi,
        MoonRat.address,
        {
            gas: 100000,
            from: accounts[0]
        }
    )
}

export const getMoonRatPoolContract = async () => {
    const accounts = await web3Client.eth.getAccounts()
    const contract = await getMoonRatContract(web3Client)
    const pairContractAddress = await contract.methods.pancakePair().call()

    return new web3Client.eth.Contract(
        MoonRatLiquidityPool.jsonInterface.abi,
        pairContractAddress,
        {
            gas: 100000,
            from: accounts[0]
        }
    )
}


export const getContractInfo = async () => {
    const contract = await getMoonRatContract(web3Client)
    const poolContract = await getMoonRatPoolContract(web3Client)

    const maxTXAmount = await contract.methods._maxTxAmount().call()
    const reserves = await poolContract.methods.getReserves().call()

    const rewardCycleBlock = await contract.methods.rewardCycleBlock().call()
    const bnbRewardPool = await web3Client.eth.getBalance(MoonRat.address)

    const {RAW: {BNB: {USD: {PRICE}}}} = await fetchBNBPrice();

    const {
        '0': mratBalance, '1': bnbBalance
    } = reserves

    const rate = bnbBalance / mratBalance
    const maxBNBAmount = (rate * maxTXAmount).toFixed(0)
    const burnedMrat = await contract.methods.balanceOf('0x000000000000000000000000000000000000dEaD').call()
    const totalSupply = await contract.methods.totalSupply().call()


    const data = {
        mratBalance: Number(web3Client.utils.fromWei(mratBalance.toString(), 'gwei')),
        maxTxAmount: Number(web3Client.utils.fromWei(maxTXAmount.toString(), 'gwei')),
        bnbBalance: Number(web3Client.utils.fromWei(bnbBalance.toString(), 'ether')),
        maxBNBAmount: Number(web3Client.utils.fromWei(maxBNBAmount.toString(), 'ether')),
        rewardCycleBlock: Number(rewardCycleBlock) * 1000,
        bnbInPool: Number(web3Client.utils.fromWei(bnbRewardPool.toString(), 'ether')) || 0,
        bnbPrice: PRICE,
        burnedMrat: Number(web3Client.utils.fromWei(burnedMrat.toString(), 'gwei')),
        totalSupply: Number(web3Client.utils.fromWei(totalSupply.toString(), 'gwei'))
    }

    const mratPrice = (data.bnbBalance / data.mratBalance) * PRICE;
    const mratMilPrice = mratPrice * 10 ** 6;
    const liquidityValue = data.bnbBalance * PRICE * 2;
    const marketCap = (data.totalSupply - data.burnedMrat) * mratPrice;


    data.mratMilPrice = mratMilPrice
    data.marketCap = marketCap
    data.liquidityValue = liquidityValue

    return data;
}
