<template>

  <div>
    <Header/>

    <section id="guide">

      <div class="backtotop display" :class="{'display': show_back_to_top}">
        <a href="#guide" v-scroll-to="'#guide'">
          <i class="fa fa-arrow-up" aria-hidden="true">
          </i>
        </a>
      </div>

      <div class="container">
        <h1>Privacy Policy</h1>
        <br/>
        <br/>

        <p>Last Updated: April 26, 2021</p>
        <br/>
        <br/>
        We <strong>MoonRat</strong> (MoonRat.Finance), are committed to protecting any data that we collect concerning
        you. By using our
        services you agree to the use of the data that we collect in accordance with this <strong>Privacy
        Policy</strong>.
        <br/> <br/>
        We are committed to protecting your privacy.
        <br/> <br/>
        We collect the minimum amount of information about you that is commensurate with providing you with a
        satisfactory
        service. This <strong>Policy</strong> indicates the type of processes that may result in data being collected
        about you. The
        purpose of this <strong>Privacy Policy</strong> to enable you to understand which personal identifying
        information ("PI", "Personal
        Information") of yours is collected, how and when we might use your information, who has access to this
        information, and how you can correct any inaccuracies in the information. To better protect your privacy, we
        provide this notice explaining our online information practices and the choices you can make about the way your
        information is collected and used. To make this notice easy to find, we make it available on our website.
        <br/> <br/>
        <h4>Information Collected</h4>
        <br/>
        We may collect any or all of the information that via both automated means such as communications profiles and
        cookies. <strong>Personal Information</strong> you give us depends on the type of service, support, or sale
        inquiry, and may
        include your name, address, telephone number, fax number and email address, dates of service provided, types of
        service provided, payment history, manner of payment, amount of payments, date of payments, domain name or other
        payment information. All sensitive information is collected on a secure server and data is transferred . When
        transferring personal information a security icon will appear in your browser.
        <br/><br/>
        <h4>Information Use</h4>
        <br/>
        This information is used for billing and to provide service and support to our customers. We may also study this
        information to determine our customers needs and provide support for our customers. All reasonable precautions
        are
        taken to prevent unauthorised access to this information. This safeguard may require you to provide additional
        forms of identity should you wish to obtain information about your account details. RunePay may email its
        monthly
        newsletter to the primary contact e-mail on file, but customers are able to opt out of this newsletter at any
        time.
        <br/><br/>
        We use IP addresses to analyze trends, administer our site and servers, track access, and gather broad
        demographic
        information for aggregate use. IP addresses are not linked to personally identifiable information. It is
        possible
        that personal information about a customer may be included in the log files due to the normal functions of IP
        addresses and SaaS applications.
        <br/><br/>
        <h4>Cookies</h4>
        <br/>
        Your Internet browser has the in-built facility for storing small text files - "cookies" - that hold information
        which allows a website to recognize your account. We use cookies to save your preferences and login information,
        and provide personalized functionality. You can reject cookies by changing your browser settings, but be aware
        that this will disable some of the functionality on the MoonRat.Finance website. More information about cookies can be
        found at https://en.wikipedia.org/wiki/HTTP_cookie.
      </div>

    </section>
  </div>
</template>

<script>
import Header from "@/components/Header";
export default {
  name: 'Privacy',
  components: {
    Header
  },
  props: {},
  data() {
    return {
      sm: '0x68590a47578e5060a29fd99654f4556dbfa05d10',
      show_back_to_top: false
    }
  },
  methods: {
    onScroll() {

      // Get the current scroll position
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      if (currentScrollPosition < 30) {
        this.show_back_to_top = false
      } else {
        this.show_back_to_top = true
      }

    },
    copy() {
      var self = this
      const testingCodeToCopy = document.querySelector('#copy-value')
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()

      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful'

        self.$notify({
          title: 'Success',
          message: 'Copied value ' + msg,
          type: 'success'
        })
      } catch (err) {
        self.$notify({
          title: 'Error',
          message: 'Oops, unable to copy',
          type: 'success'
        })
      }

      window.getSelection().removeAllRanges()
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>


</style>
