<template>
  <section id="slide-bnb">
    <div class="card-wrapper">
      <div class="part-1" v-scrollanimation>
        <div class="title-1">
          Earning BNB Result
        </div>
        <div class="desc-1">
          Thousand of Rat Gangs (a fan name for MoonRat) receive BNB weekly just by holding $SMRAT.
        </div>

        <div class="button-wrapper" v-animate-css.hover="'headShake'">
          <a class="button-custom" href="https://app.moonrat.finance/" target="_blank">
            Launch App
          </a>
        </div>


      </div>

      <div class="part-2" v-scrollanimation>

        <el-carousel height="550px" arrow="always">
          <el-carousel-item key="0">
            <div class="text-center img-content">
              <a href="https://twitter.com/HolisticArtist2/status/1387369925245816832/photo/1" target="_blank">
                <img :src="require('@/assets/images/earnbnb/1.png')">
              </a>
              <a href="" target="_blank" class="logo-twitter">
                <i class="fab fa-twitter" aria-hidden="true"></i>
              </a>
            </div>
          </el-carousel-item>

          <el-carousel-item key="1">
            <div class="text-center img-content">
              <a href="https://twitter.com/Eduardo36558520/status/1391562888133894147/photo/1" target="_blank">
                <img :src="require('@/assets/images/earnbnb/2.png')">
              </a>
              <a href="" target="_blank" class="logo-twitter">
                <i class="fab fa-twitter" aria-hidden="true"></i>
              </a>
            </div>
          </el-carousel-item>

          <el-carousel-item key="2">
            <div class="text-center img-content">
              <a href="https://twitter.com/joneysseric/status/1387367482328035328/photo/1" target="_blank">
                <img :src="require('@/assets/images/earnbnb/3.png')">
              </a>
              <a href="" target="_blank" class="logo-twitter">
                <i class="fab fa-twitter" aria-hidden="true"></i>
              </a>
            </div>
          </el-carousel-item>

          <el-carousel-item key="3">
            <div class="text-center img-content">
              <a href="https://twitter.com/jonsson8007/status/1387577098383073281/photo/1" target="_blank">
                <img :src="require('@/assets/images/earnbnb/4.png')">
              </a>
              <a href="" target="_blank" class="logo-twitter">
                <i class="fab fa-twitter" aria-hidden="true"></i>
              </a>
            </div>
          </el-carousel-item>

        </el-carousel>
      </div>

    </div>
  </section>
</template>

<script>
import {Tweet} from 'vue-tweet-embed'

export default {
  name: 'SlideRat',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Tweet,
  },
  props: {},
  data() {
    return {
      list_image_reward: []
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.before-enter {
  opacity: 0;
  transform: translateY(100px);
  transition: all 1s ease-out;
}

/*
  If the element intersects with the viewport, the before-enter class is added.
*/
.enter {
  opacity: 1;
  transform: translateY(0px);
}

</style>
