<template>
    <section id="guide">

        <div class="backtotop display" :class="{'display': show_back_to_top}">
            <a href="#guide" v-scroll-to="'#guide'">
                <i class="fa fa-arrow-up" aria-hidden="true">
                </i>
            </a>
        </div>

        <div class="container">

            <div class="part-1 mt-5">
                <h1 class="text-center text-main-section-guide">
                    How to migrate MoonRat (MRAT) <br> to Secured MoonRat (SMRAT)
                </h1>

                <div class="mt-5">
                    <div class="step-section " style="margin-top: 30px">
                        - Tutorial swap MRAT to SMRAT on <span class="text-color">TrustWallet</span>
                    </div>

                    <div class="text-center mt-2">
                        <iframe width="80%" height="350" src="https://www.youtube.com/embed/AT8d4OvEtr0"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                    </div>
                </div>

                <div class="mt-5">
                    <div class="step-section " style="margin-top: 30px">
                        - How To See Your SMRAT Balance on <span class="text-color">TrustWallet</span>
                    </div>

                    <div class="text-center mt-2">
                        <iframe width="80%" height="350" src="https://www.youtube.com/embed/gmtqOL1jmzM"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                    </div>
                </div>

                <div class="mt-5">
                    <div class="step-section " style="margin-top: 30px">
                        - Tutorial swap MRAT to SMRAT on <span class="text-color">MetaMask</span>
                    </div>

                    <div class="text-center mt-2">
                        <iframe width="80%" height="350" src="https://www.youtube.com/embed/TJhc6sNatDY"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                    </div>
                </div>

                <div class="mt-5">
                    <div class="step-section " style="margin-top: 30px">
                        - How To See Your SMRAT Balance on <span class="text-color">MetaMask</span>
                    </div>

                    <div class="text-center mt-2">
                        <iframe width="80%" height="350" src="https://www.youtube.com/embed/D5j6LikhiG0"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                    </div>
                </div>

                <div class="mt-5">
                    <div class="step-section " style="margin-top: 30px">
                        - Tutorial swap MRAT to SMRAT on <span class="text-color">SafePal wallet</span>
                    </div>

                    <div class="text-center mt-2">
                        <iframe width="80%" height="350" src="https://www.youtube.com/embed/bLmVuoo4poU"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen>
                        </iframe>
                    </div>
                </div>

                <div class="mt-5">
                    <div class="step-section " style="margin-top: 30px">
                        - How to use MoonRat (SMRAT) on <span class="text-color">SafePal wallet</span>
                    </div>

                    <div class="text-center mt-2">
                        <iframe width="80%" height="350" src="https://www.youtube.com/embed/ObZXYxJpgwA"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                    </div>
                </div>


            </div>
        </div>

    </section>
</template>

<script>
    export default {
        name: 'GuideMigrate',
        components: {},
        props: {},
        data() {
            return {
                sm: '0x68590a47578e5060a29fd99654f4556dbfa05d10',
                show_back_to_top: false
            }
        },
        methods: {
            onScroll() {

                // Get the current scroll position
                const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
                // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
                if (currentScrollPosition < 30) {
                    this.show_back_to_top = false
                } else {
                    this.show_back_to_top = true
                }

            },
            copy() {
                var self = this
                const testingCodeToCopy = document.querySelector('#copy-value')
                testingCodeToCopy.setAttribute('type', 'text')
                testingCodeToCopy.select()

                try {
                    var successful = document.execCommand('copy')
                    var msg = successful ? 'successful' : 'unsuccessful'

                    self.$notify({
                        title: 'Success',
                        message: 'Copied value ' + msg,
                        type: 'success'
                    })
                } catch (err) {
                    self.$notify({
                        title: 'Error',
                        message: 'Oops, unable to copy',
                        type: 'success'
                    })
                }

                window.getSelection().removeAllRanges()
            },
        },
        mounted() {
            window.addEventListener('scroll', this.onScroll)
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.onScroll)
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>


</style>
