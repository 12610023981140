<template>
  <div class="padding-top-100">
    <Header/>

    <banner/>

    <more-info/>


    <slide-rat/>
    <!-- <mention-by/> -->
    <feature/>

    <!-- <subscribe2/> -->

    <how-to-buy/>


    <div class="flex-content">
      <div class="part-1">
        <transparency/>
      </div>
      <div class="part-2">
        <price-tracking/>
      </div>
    </div>


    <div class="flex-content">
      <div class="part-1">
        <chart/>
      </div>
      <div class="part-2">
        <community/>
      </div>
    </div>


    <f-a-q/>
    
  </div>
</template>

<script>
import Banner from "./Banner";
import MoreInfo from "./MoreInfo";
import Feature from "./Feature";
// import Subscribe2 from "./Subscribe2";
// import TestPaticles from "./TestPaticles";
import FAQ from "./FAQ";
import SlideRat from "./SlideRat";
import HowToBuy from "./HowToBuy.vue";
import Transparency from './Transparency.vue';
import PriceTracking from './PriceTracking.vue';
import Chart from './Chart.vue';
import Community from './Community.vue';
import Header from "@/components/Header";
// import MentionBy from "@/components/MentionBy";

// import {FlipClock} from '@mvpleung/flipclock';

export default {
  name: "Main",
  components: {
    // MentionBy,
    SlideRat,
    FAQ,
    // TestPaticles,
    // Subscribe2,
    Feature,
    MoreInfo,
    Banner,
    HowToBuy,
    Transparency,
    PriceTracking,
    Chart,
    Community,
    Header
    // FlipClock
  },
  props: {},
  data() {
    return {
      option_clock: {
        time: 0,
        clockFace: "DailyCounter",
        countdown: true,
      },
    };
  },
  mounted() {
    let now = new Date();

    let y = now.getUTCFullYear();
    let m = now.getUTCMonth();
    let d = now.getUTCDate();
    let h = now.getUTCHours();
    let mi = now.getUTCMinutes();
    let s = now.getUTCSeconds();

    var current_date = new Date(Date.UTC(y, m, d, h, mi, s));
    let target_date = new Date(Date.UTC(2021, 3, 12, 7, 0, 0));

    let diff = target_date.getTime() - current_date.getTime();

    if (diff > 0) {
      this.option_clock.time = diff / 1000;
      this.$forceUpdate();
    }
  },
};
</script>

<style>
.flex-content {
  display: flex;
  flex-wrap: wrap;
}

.flex-content .part-1 {
  width: 50%;
  background-color: white;
}

.flex-content .part-2 {
  width: 50%;
  background-color: white;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->

