<template>
  <div>

    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">

        <img
            :src="require('@/assets/images/animated/rat.svg')"
            style="width: 35px"
            alt="about-img"
        />

        <a style="margin-left: 10px;font-size: 20px" class="navbar-brand" href="/">MoonRat</a>

        <button class="navbar-toggler" type="button" data-toggle="collapse"
                data-target="#navbarSupportedContent"
                @click="show_nav_mobile = !show_nav_mobile"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <i class="fa fa-bars" aria-hidden="true"></i>
        </button>
        <div class="collapse navbar-collapse menu-main" :class="{'show': show_nav_mobile}"
             id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto menu-item" style="align-items: center; padding-right: 0px">


            <li class="nav-item" v-animate-css="'fadeInRight'">
              <a class="nav-link" href="https://docs.moonrat.finance/"
                 target="_blank">WhitePaper </a>
            </li>

            <li class="nav-item" v-animate-css="'fadeInRight'">
              <a class="nav-link" href="https://moonrat.finance/#tokenomics"
                 @click="show_nav_mobile=false">
                Tokenomics
              </a>
            </li>
            <li class="nav-item" v-animate-css="'fadeInRight'">
              <a class="nav-link" href="https://moonrat.finance/#timeline"
                 @click="show_nav_mobile=false">
                Roadmap
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>

</template>

<script>

export default {
  name: 'Header',
  data() {
    return {
      have_nav: false,
      always_fixed_navbar: false,
      show_nav_mobile: false,
    }
  },
  methods: {
    commingSoon() {

      this.$swal({
        title: 'Coming Soon ...',
        width: 400,
        padding: '2em',
        background: '#fff url(https://i.ibb.co/gwh2dbd/moon.png)',
        backdrop: `
                                rgba(0, 0, 0, 0.5)
                                url("https://i.ibb.co/qFgF3yZ/moonrat-02.gif")
                                left top
                                no-repeat
                              `
      });

    },
    commingSoon1() {
      window.open('https://app.moonrat.finance', '_blank');
    },
    onScroll() {
      if (!this.always_fixed_navbar) {
        // Get the current scroll position
        const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
        // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
        if (currentScrollPosition < 30) {
          this.have_nav = false
        } else {
          this.have_nav = true
        }
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
