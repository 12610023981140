<template>
  <section id="how-to-buy" class="how-to-buy-main">
    <div class="main-title">How to buy</div>

    <div>
      <div class="row" style="margin-left: 0px; margin-right: 0px" v-scrollanimation>
        <a class="list-item-hover" target="_blank" href="https://community.moonrat.finance/post/tutorial-on-safepal-metamask-application-metamask-extension-trustwallet-60925d76499714b5db16bddf">
          <div class="item-hover">
            <div class="item-icon">
              <i class="el-icon-mobile-phone"></i>
            </div>
            <div class="item-content">
              <div class="item-title">How to buy on Mobile</div>
              <div class="item-desc">
                Learn about how to buy SMRAT in mobile
              </div>
            </div>
          </div>

          <a class="item-hover" target="_blank" href="https://community.moonrat.finance/post/tutorial-on-safepal-metamask-application-metamask-extension-trustwallet-60925d76499714b5db16bddf">
            <div class="item-icon">
              <i class="el-icon-s-platform"></i>
            </div>
            <div class="item-content">
              <div class="item-title">How to buy on Desktop</div>
              <div class="item-desc">
                Learn about how to buy SMRAT in pc/desktop
              </div>
            </div>
          </a>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HowToBuy",
  components: {},
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.how-to-buy-main {
  margin-bottom: 70px;
}
</style>
