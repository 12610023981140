<template>
  <section id="banner">

    <div class="backtotop" :class="{'display': show_back_to_top}">
      <a href="#banner" v-scroll-to="'#app'">
        <i class="fa fa-arrow-up" aria-hidden="true">
        </i>
      </a>
    </div>
    <div class="container-fluid">
      <div class="content-wrapper card-wrapper">
        <div data-poster-url="https://temp-mr.s3.amazonaws.com/cap2_temp.jpg"
             data-video-urls="https://temp-mr.s3.amazonaws.com/video_background.mp4"
             data-autoplay="true" data-loop="true" data-wf-ignore="true"
             class="rounded-container metaverse-video w-background-video w-background-video-atom">

          <video autoplay="" loop=""
                 style="background-image:url(&quot;https://temp-mr.s3.amazonaws.com/cap2_temp.jpg&quot;);
                        background-repeat: no-repeat;
                        background-size: cover;"
                 muted="" playsinline="" data-wf-ignore="true">
            <source src="https://temp-mr.s3.amazonaws.com/video_background.mp4"
                    data-wf-ignore="true">

          </video>

          <div class="home-hero-wrapper">

            <div class="text-content part-1">
              <div class="home-hero-inner-padding " v-animate-css="'slideInUp'">
                A new way to earn BNB
              </div>
              <div class="hero-subtitle" v-animate-css="'slideInUp'">
                MoonRat brings the most revolutionary mechanism to BSC ecosystem: Earn $BNB just by
                holding $SMRAT token.
                <br/>
                <br/>
              </div>
              <div class="row container-fluid mt-3" v-animate-css="'slideInUp'">
                <div class="col-lg-12 col-sm-12 col-md-12 text-sm-center text-md-center text-lg-left mb-5">
                  <a class="bor super" style="border-radius: 5px; margin-left: 0"
                     href="https://app.moonrat.finance/" target="_blank">Launch App</a>
                  <a style="border-radius: 5px; margin-left: 24px; color: white!important; border: 1px solid beige; padding: 10px"
                     href="https://www.certik.org/projects/moonratfinance/" target="_blank">Certik Audit</a>
                </div>
              </div>
            </div>

            <div class="part-2" v-animate-css="'zoomInDown'">
              <img style="border: none" :src="require('@/assets/images/animated/moon_rat_rotate.svg')">
            </div>

          </div>
          <statistic/>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import Statistic from "@/components/Statistic";
export default {
  name: 'Banner',
  components: {
    Statistic
  },
  data() {
    return {
      show_back_to_top: false
    }
  },
  methods: {
    launchApp() {
      window.open("https://app.moonrat.finance/", "_blank")
    },
    onScroll() {
      if (!this.always_fixed_navbar) {
        // Get the current scroll position
        const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
        // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
        if (currentScrollPosition < 30) {
          this.show_back_to_top = false
        } else {
          this.show_back_to_top = true
        }
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.
@media (max-width: 992px) {
}
</style>