<template>
  <section id="faq">
    <div>
      <div class="main-title">FAQ</div>

      <div class="faq-content" v-scrollanimation>
        <el-collapse v-model="activeNames" @change="handleChange">
          <el-collapse-item
            v-for="(item, index) in data_faq"
            :title="item.title"
            :key="index"
            :name="index"
          >
            <div v-html="item.desc"></div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "FAQ",
  components: {},
  props: {},
  data() {
    return {
      activeNames: [0],
      data_faq: [
        {
          title: "How many BNB can I claim?",
          desc:
            "You can claim the amount of the BNB based on the ratio of $SMRAT you hold/the total BNB pool. <br> For example, you hold 1% of $SMRAT, you can withdraw 1% of the BNB reward pool.",
        },
        {
          title: "What is the BNB pool?",
          desc:
            "4% of every transaction is taken and re-distributed to all MoonRat holders in $BNB. This amount of BNB called the BNB pool.",
        },
        {
          title: "What if I don’t claim BNB reward in my cycle?",
          desc:
            "If you don’t claim at your cycle and other holders claim, the BNB pool will reduce. <br> So then the ratio of your $SMRAT/BNB pool reduces as well. That’s why you should claim as soon as your cycle is ready ",
        },
        {
          title: "What do I need to do to passively earn BNB?",
          desc:
            "Simply holding $SMRAT will earn you a share of the BNB rewards pool, proportional to the amount of $SMRAT you hold. You will also earn $SMRAT passively too, which gets put straight in your wallet",
        },
        {
          title:
            "What time zone is the MoonRat app on? (relating to reward claim timer)",
          desc:
            "It is converted to your timezone",

        },
        {
          title: "How can I claim my BNB?",
          desc:
            'Simply have your wallet connected through the Moonrat Dapp on the website, click "Collect my bnb". Please note that you must have BNB in your wallet that will be used to cover transfer fees.',
        },
        {
          title: "Does my BNB stack if I don't claim it?",
          desc:
            'No. This is because it\'s not "your" BNB, meaning it is not an amount of BNB that is set aside for you, but rather a set share of the pool of BNB. As people claim their BNB, the total pool will decrease, which in turn means the BNB value of your set share will decrease. In short, it is best to claim your BNB as soon as possible.',
        },
        {
          title: "Why is my collectible BNB less than yesterday?",
          desc:
            "Several factors impact BNB claim amount. <br> 1. The amount of BNB in the pool is distributed based on percentage. The more BNB there, the more you get, however, it is ALWAYS based on percentage. The less there, the less you get. IT IS NOT CUMULATIVE <br> 2. The more people claim, the less BNB will be available for everyone else <br> 3. There is a cycle before you can collect BNB and the more transactions you make with this coin, the more the cycle changes. Be aware of that when you are thinking of selling high to buy low",
        }
      ],
    };
  },
  methods: {
    handleChange(val) {
      console.log(val);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.faq-content {
  padding: 50px 32px;
  background-color: white;
  border-radius: 0px;
}
</style>
