<template>
  <div>
    <div class="row">
      <div class="col-lg-12  statistic-wrapper">
        <div class="statistic-block button-wrapper col-lg-12 col-md-6 col-sm-12">
          <div class="linear-block">
            <h5 class="text-1">Max Transaction Amount</h5>
            <div class="text-2">
              $SMRAT
              <count-to :start-val="0" :end-val="statistic.maxTxAmount" :decimals="0" :duration="1000"
                        class="card-panel-num"/>
            </div>
          </div>
        </div>

        <div class="statistic-block button-wrapper col-lg-12 col-md-6 col-sm-12">
          <div class="linear-block">

            <h5 class="text-1">Total BNB in reward pool</h5>
            <div class="text-2">
              $BNB
              <count-to :start-val="0" :end-val="statistic.bnbInPool" :decimals="2" :duration="1000"
                        class="card-panel-num"/>
            </div>
          </div>
        </div>

        <div class="statistic-block button-wrapper col-lg-12 col-md-6 col-sm-12">
          <div class="linear-block">
            <h5 class="text-1">Total Liquidity Pool</h5>
            <div class="text-2">
              $
              <count-to :start-val="0" :end-val="statistic.liquidityValue" :decimals="2" :duration="1000"
                        class="card-panel-num"/>
            </div>
          </div>
        </div>

        <div class="statistic-block button-wrapper col-lg-12 col-md-6 col-sm-12">
          <div class="linear-block">

            <h5 class="text-1">1 Mil SMRAT Price</h5>
            <div class="text-2">
              $
              <count-to :start-val="0" :end-val="statistic.mratMilPrice" :decimals="9" :duration="1000"
                        class="card-panel-num"/>
            </div>
          </div>
        </div>

        <div class="statistic-block button-wrapper col-lg-12 col-md-6 col-sm-12">
          <div class="linear-block">

            <h5 class="text-1">SMRAT Marketcap</h5>
            <div class="text-2">
              $
              <count-to :start-val="0" :end-val="statistic.marketCap" :decimals="2" :duration="1000"
                        class="card-panel-num"/>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import {getContractInfo} from "@/libs/moonrat";
import CountTo from 'vue-count-to';

export default {
  name: "Statistic",
  components: {
    CountTo
  },
  data() {
    return {
      statistic: {}
    }
  },
  methods: {
    async getStatistic() {
      const result = await getContractInfo();
      console.log({result});
      this.statistic = result;
    },
  },
  mounted() {
    this.getStatistic();

    setInterval(() => {
      this.getStatistic();
    }, 3000);
  }
}
</script>

<style scoped>
.statistic-wrapper {
  display: inline-block;
  /* margin-bottom: 50px; */
  margin-top: 100px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.statistic-wrapper .text-1 {
  font-size: 14px;
}

.statistic-wrapper .text-2 {
  font-size: 14px;
}

.statistic-block {
  display: inline-block;
  width: 20%;
  padding: 0px;
}

.linear-block {
  padding: 20px;
  border-radius: 5px;
  color: white !important;
  /*background-size: 200% auto;*/
  /*background-image: linear-gradient(*/
  /*    90deg, #1b4581, #46ABf8 51%, #1b4581);*/
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  position: relative;
  transition: all linear .3s;
  text-align: center;
}

@media (max-width: 991px) {
  .statistic-block {
    width: 100%;
    margin-bottom: 5px;
  }


  /*.statistic-wrapper {*/
  /*    display: none;*/
  /*}*/
}
</style>
