<template>

  <div>

    <Header/>

    <section id="guide">

      <div class="backtotop display" :class="{'display': show_back_to_top}">
        <a href="#guide" v-scroll-to="'#guide'">
          <i class="fa fa-arrow-up" aria-hidden="true">
          </i>
        </a>
      </div>

      <div class="container">
        <h1>Terms and Conditions</h1>
        <p>Last updated: April 26, 2021</p>
        <br/>
        <br/>

        <strong style="color: red">Please read these terms and conditions carefully before using Our Service.</strong>
        <br/>
        <br/>
        <h2>Interpretation and Definitions</h2>
        <br/>

        <h4>Interpretation</h4>
        <br/>

        The words of which the initial letter is capitalized have meanings defined under the following conditions. The
        following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
        <br/>
        <br/>

        <h4>Definitions</h4>
        <br/>
        <strong>For the purposes of these Terms and Conditions:</strong>
        <br/>
        <br/>

        <strong>Country</strong> refers to: Utah, United States.
        <br/>
        <br/>

        <strong>MoonRat.Finance</strong> (referred to as either "the MoonRat.Finance", "We", "Us" or "Our" in this
        Agreement) refers to <strong>MoonRat.Finance</strong>.
        <br/>
        <br/>


        <strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a
        party, where "control"
        means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election
        of
        directors or other managing authority.
        <br/>
        <br/>

        <strong>Device</strong> means any device that can access the <strong>Service</strong> such as a computer, a
        cellphone or a digital tablet.
        <br/>
        <br/>

        <strong>Service</strong> refers to the Website.
        <br/>
        <br/>

        <strong>Terms and Conditions</strong> (also referred as <strong>"Terms"</strong>) mean these <strong>Terms and
        Conditions</strong> that form the entire agreement
        between You and the MoonRat.Finance regarding the use of the Service. This Terms and Conditions agreement has
        been created
        with the help of the Terms and Conditions Generator.
        <br/>
        <br/>

        <strong>Third-party Social Media Service</strong> means any services or content (including data, information,
        products or services)
        provided by a third-party that may be displayed, included or made available by the Service.
        <br/>
        <br/>

        <strong>Website</strong> refers to MoonRat, accessible from <a href="https://moonrat.finance">https://moonrat.finance</a>
        and all sub-domains belong to <a href="https://moonrat.finance">https://moonrat.finance</a>
        <br/>
        <br/>

        You means the individual accessing or using the <strong>Service</strong>, or the
        <strong>MoonRat.Finance</strong>, or other legal entity on behalf of which
        such individual is accessing or using the Service, as applicable.
        <br/>
        <br/>

        <h4>Acknowledgment</h4>
        <br/>

        These are the <strong>Terms and Conditions</strong> governing the use of this <strong>Service</strong> and the
        agreement that operates between You
        and the MoonRat.Finance. These <strong>Terms and Conditions</strong> set out the rights and obligations of all
        users regarding the use of
        the Service.
        <br/>
        <br/>


        Your access to and use of the <strong>Service</strong> is conditioned on <strong>Your acceptance of and
        compliance</strong> with these <strong>Terms and
        Conditions</strong>. These <strong>Terms and Conditions</strong> apply to all visitors, users and others who
        access or use the Service.
        <br/>
        <br/>

        By accessing or using the <strong>Service</strong> <strong>You</strong> agree to be bound by these <strong>Terms
        and Conditions</strong>. If You disagree with any
        part of these <strong>Terms and Conditions</strong> then You may not access the <strong>Service</strong>.
        <br/>
        <br/>

        You represent that you are over the age of 18. The <strong>MoonRat.Finance</strong> does not permit those under
        18 to use the <strong>Service</strong>.
        <br/>
        <br/>

        Your access to and use of the <strong>Service</strong> is also conditioned on Your acceptance of and compliance
        with the <strong>Privacy
        Policy</strong> of the <strong>MoonRat.Finance</strong>. Our <strong>Privacy Policy</strong> describes <strong>Our
        policies</strong> and procedures on the collection, use and
        disclosure of Your personal information when <strong>You</strong> use the <strong>Application</strong> or the
        <strong>Website</strong> and tells <strong>You</strong> about <strong>Your
        privacy rights</strong> and how the law protects You. Please read <strong>Our Privacy Policy</strong> carefully
        before using <strong>Our Service</strong>.
        <br/>
        <br/>

        <h4>Links to Other Websites</h4>
        <br/>

        <strong>Our Service</strong> may contain links to third-party web sites or services that are not owned or
        controlled by the
        MoonRat.Finance.
        <br/>
        <br/>

        The <strong>MoonRat.Finance</strong> has no control over, and assumes no responsibility for, the content,
        privacy policies, or practices of
        any third party web sites or services. You further acknowledge and agree that the
        <strong>MoonRat.Finance</strong> shall not be responsible
        or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection
        with
        the use of or reliance on any such content, goods or services available on or through any such web sites or
        services.
        <br/>
        <br/>

        We strongly advise <strong>You</strong> to read the terms and conditions and privacy policies of any third-party
        web sites or
        services that <strong>You</strong> visit.
        <br/>
        <br/>

        <h4>Termination</h4>
        <br/>
        We may terminate or suspend <strong>Your</strong> access immediately, without prior notice or liability, for any
        reason whatsoever,
        including without limitation if <strong>You</strong> breach these <strong>Terms and Conditions</strong>.
        <br/>
        <br/>
        Upon termination, <strong>Your right</strong> to use the <strong>Service</strong> will cease immediately.
        <br/>
        <br/>
        <h4>Limitation of Liability</h4>
        <br/>
        Notwithstanding any damages that <strong>You</strong> might incur, the entire liability of the <strong>MoonRat.Finance</strong>
        and any of its suppliers
        under any provision of this <strong>Terms and Your exclusive remedy</strong> for all of the foregoing shall be
        limited to the
        amount actually paid by <strong>You</strong> through the <strong>Service</strong> or 100 USD if
        <strong>You</strong> haven't purchased anything through the Service.
        <br/>
        <br/>

        To the maximum extent permitted by applicable law, in no event shall the <strong>MoonRat.Finance</strong> or its
        suppliers be liable for
        any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages
        for
        loss of profits, loss of data or other information, for business interruption, for personal injury, loss of
        privacy arising out of or in any way related to the use of or inability to use the <strong>Service</strong>,
        third-party software
        and/or third-party hardware used with the Service, or otherwise in connection with any provision of this
        <strong>Terms</strong>),
        even if the <strong>MoonRat.Finance</strong> or any supplier has been advised of the possibility of such damages
        and even if the remedy
        fails of its essential purpose.
        <br/>
        <br/>

        Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or
        consequential damages, which means that some of the above limitations may not apply. In these states, each
        party's
        liability will be limited to the greatest extent permitted by law.
        <br/>
        <br/>

        <h4>"AS IS" and "AS AVAILABLE" Disclaimer</h4>
        <br/>

        The <strong>Service</strong> is provided to You <strong>"AS IS"</strong> and <strong>"AS AVAILABLE"</strong> and
        with all faults and defects without warranty of any
        kind. To the maximum extent permitted under applicable law, the <strong>MoonRat.Finance</strong>, on its own
        behalf and on behalf of its
        <strong>Affiliates</strong> and its and their respective licensors and service providers, expressly disclaims
        all warranties,
        whether express, implied, statutory or otherwise, with respect to the <strong>Service</strong>, including all
        implied warranties of
        merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out
        of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the
        <strong>MoonRat.Finance</strong> provides no warranty or undertaking, and makes no representation of any kind
        that the <strong>Service</strong> will meet
        Your requirements, achieve any intended results, be compatible or work with any other software, applications,
        systems or services, operate without interruption, meet any performance or reliability standards or be error
        free
        or that any errors or defects can or will be corrected.
        <br/>
        <br/>

        Without limiting the foregoing, neither the <strong>MoonRat.Finance</strong> nor any of the MoonRat.Finance's
        provider makes any representation or
        warranty of any kind, express or implied:
        <br/>
        (i) as to the operation or availability of the <strong>Service</strong>, or the
        information, content, and materials or products included thereon;
        <br/>
        (ii) that the <strong>Service</strong> will be uninterrupted or error-free;
        <br/>
        (iii) as to the accuracy, reliability, or currency of any information or content provided through the
        Service; or
        <br/>
        (iv) that the <strong>Service</strong>, its servers, the content, or e-mails sent from or on behalf of the
        <strong>MoonRat.Finance</strong> are
        free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.

        <br/>
        <br/>
        Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable
        statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But
        in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent
        enforceable under applicable law.
        <br/>
        <br/>
        <h4>Governing Law</h4>
        <br/>
        The laws of the <strong>Country</strong>, excluding its conflicts of law rules, shall govern this <strong>Terms
        and Your use of the
        Service</strong>. Your use of the <strong>Application</strong> may also be subject to other local, state,
        national, or international laws.
        <br/>
        <br/>

        <h4>Disputes Resolution</h4>
        <br/>

        If <strong>You</strong> have any concern or dispute about the <strong>Service</strong>, You agree to first try
        to resolve the dispute informally by
        contacting the <strong>MoonRat.Finance</strong>.
        <br/>
        <br/>

        <h5>For European Union (EU) Users</h5>
        <br/>
        If <strong>You</strong> are a <strong>European Union</strong> consumer, you will benefit from any mandatory
        provisions of the law of the country in
        which you are resident in.
        <br/>
        <br/>
        <h5>United States Legal Compliance</h5>
        <br/>
        <strong>You represent and warrant that</strong>
        <br/>
        (i) You are not located in a country that is subject to the <strong>United States</strong>
        government embargo, or that has been designated by the <strong>United States</strong> government as a "terrorist
        supporting"
        country, and
        <br/>
        (ii) You are not listed on any United States government list of prohibited or restricted parties.
        <br/> <br/>
        <h4>Severability and Waiver</h4>
        <br/>
        <h5>Severability</h5>
        <br/>
        If any provision of these <strong>Terms</strong> is held to be unenforceable or invalid, such provision will be
        changed and
        interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law
        and the remaining provisions will continue in full force and effect.
        <br/><br/>
        <h5>Waiver</h5>
        <br/>
        Except as provided herein, the failure to exercise a right or to require performance of an obligation under this
        <strong>Terms</strong> shall not effect a party's ability to exercise such right or require such performance at
        any time thereafter
        nor shall be the waiver of a breach constitute a waiver of any subsequent breach.
        <br/><br/>
        <h5>Translation Interpretation</h5>
        <br/>
        These <strong>Terms and Conditions</strong> may have been translated if We have made them available to <strong>You</strong>
        on our <strong>Service</strong>. You
        agree that the original English text shall prevail in the case of a dispute.
        <br/><br/>
        <h5>Changes to These Terms and Conditions</h5>
        <br/>
        We reserve the right, at <strong>Our sole discretion</strong>, to modify or replace these <strong>Terms</strong>
        at any time. If a revision is
        material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking
        effect.
        What constitutes a material change will be determined at Our sole discretion.
        <br/><br/>
        By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the
        revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the
        <strong>Service</strong>.
        <br/><br/>
        <h4>Contact Us</h4>
        <br/>
        If you have any questions about these <strong>Terms and Conditions</strong>,
        <br/>
        You can contact us:
        <br/>
        By email: <a href="mailto:hello@moonrat.finance">hello@moonrat.finance</a>
      </div>

    </section>
  </div>
</template>

<script>
import Header from "@/components/Header";
export default {
  name: 'Terms',
  components: {
    Header
  },
  props: {},
  data() {
    return {
      sm: '0x68590a47578e5060a29fd99654f4556dbfa05d10',
      show_back_to_top: false
    }
  },
  methods: {
    onScroll() {

      // Get the current scroll position
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      if (currentScrollPosition < 30) {
        this.show_back_to_top = false
      } else {
        this.show_back_to_top = true
      }

    },
    copy() {
      var self = this
      const testingCodeToCopy = document.querySelector('#copy-value')
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()

      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful'

        self.$notify({
          title: 'Success',
          message: 'Copied value ' + msg,
          type: 'success'
        })
      } catch (err) {
        self.$notify({
          title: 'Error',
          message: 'Oops, unable to copy',
          type: 'success'
        })
      }

      window.getSelection().removeAllRanges()
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>


</style>
