<template>
  <div>
    <Header/>
    <section id="guide">

      <div class="backtotop display" :class="{'display': show_back_to_top}">
        <a href="#guide" v-scroll-to="'#guide'">
          <i class="fa fa-arrow-up" aria-hidden="true">
          </i>
        </a>
      </div>

      <div class="container">

        <div class="part-1 mt-5">
          <h1 class="text-center text-main-section-guide">
            How to buy MoonRat ($SMRAT)
          </h1>

          <div class="text-center text-color">
            via MetaMask on the browser (Laptop/PC)
          </div>

          <div class="block-wrapper">
            <div class="block-content" v-scroll-to="'#create-metamark'">
              Create MetaMask wallet
            </div>

            <div class="block-content" v-scroll-to="'#buy-mrat-pancake'">
              Buy $SMRAT on PancakeSwap
            </div>

            <div class="block-content" v-scroll-to="'#claim-bnb'">
              Collect $BNB
            </div>

            <a target="_blank" class="block-content" href="https://moonrat.finance/guide/migrate">
              Migrate to SMRAT
            </a>
          </div>

          <div>

            <div id="create-metamark">
              <div class="main-title">
                Create the metamask wallet
              </div>

              <div class="text-title-sub">
                1. Download/Install MetaMask from Chrome at this link: <a
                  href="https://metamask.io/download.html">https://metamask.io/download.html</a>
              </div>
              <div class="image-section">
                <img :src="require('@/assets/images/guide/Picture1.png')">
              </div>
              <div class="text-title-sub">
                2. Open a wallet or import one, be sure to store your keyphrase safely
              </div>
              <div class="image-section">
                <img :src="require('@/assets/images/guide/Picture2.png')">
              </div>

              <div class="text-title-sub">
                3. Setting BSC network - You can buy $SMRAT (MoonRat) with the BSC network only.
              </div>

              <div class="image-section">
                <div>
                  <img :src="require('@/assets/images/guide/Picture3.png')">
                </div>
                <div>
                  <img :src="require('@/assets/images/guide/new.jpg')">
                </div>
              </div>

              <div class="content-section">
                The BSC Network information
                <ul class="style-ul">
                  <li>
                    Network Name: <span class="text-color"> BSC Mainnet</span>
                  </li>
                  <li>
                    New RPC URL: <span class="text-color">https://bsc-dataseed1.binance.org/</span>
                    or
                    <span class="text-color">https://bsc-dataseed2.binance.org/</span>
                  </li>
                  <li>
                    ChainID: <span class="text-color">56, or 0x38 if 56 doesn’t work</span>
                  </li>
                  <li>
                    Symbol: <span class="text-color">BNB</span>
                  </li>
                  <li>
                    Block Explorer URL: <span class="text-color">https://bscscan.com/</span>
                  </li>
                </ul>
              </div>

              <div class="text-title-sub">
                4. After saving, you’re now connected to the BSC network
              </div>

              <div class="image-section">
                <img :src="require('@/assets/images/guide/Picture5.png')">
              </div>

              <div class="content-section text-color">
                Be sure that you transfer BNB to your wallet to buy $SMRAT …
                <br>
                Now, we can access MoonRat DApp and link to PancakeSwap to buy $SMRAT.
              </div>
            </div>


            <div id="buy-mrat-pancake">

              <div class="main-title">
                Buy SMRAT on PancakeSwap
              </div>

              <div class="text-title-sub">
                1. Access <a href="https://app.moonrat.finance/#/dashboard">https://app.moonrat.finance/#/dashboard</a>
              </div>

              <div class="mt-2">
                Click on the <span class="bg-color-text">Buy $SMRAT</span> button to redirect to
                Pancakeswap and buy...
              </div>

              <div class="image-section">
                <img class="w-50" :src="require('@/assets/images/guide/Picture18.png')">
              </div>

              <div class="text-title-sub">
                2. Buy $SMRAT on Pancakeswap
              </div>
              <div class="step-section">
                - Step 1: Check I understand -> Click “Continue”
              </div>
              <div class="image-section">
                <img :src="require('@/assets/images/guide/Picture6.png')">
              </div>
              <div class="content-section">
                Note: if you can’t see this popup, try to reload this page again and make sure your
                internet
                is good
                <ul style="list-style-type: circle" class="content-section">
                  <li>
                    If you have not connected to the wallet, please connect it before you buy. Make
                    sure
                    your wallet is on BSC Network.
                  </li>
                  <li>
                    After connecting to the wallet, fill out the number of $SMRAT you want to buy
                  </li>
                </ul>
              </div>

              <div class="content-section text-color">
                *Tip: You can see the maximum transaction amount of SMRAT/BNB on the app.moonrat.finance
                (The
                anti-whales feature)
              </div>
              <div class="image-section">
                <img class="w-50" :src="require('@/assets/images/guide/Picture7.png')">
              </div>
              <div class="step-section">
                - Step 2: Set the Slippage tolerance to 11-13%
              </div>
              <div class="image-section">
                <img class="w-50" :src="require('@/assets/images/guide/Picture8.png')">
              </div>
              <div class="step-section">
                - Step 3: After filling out the amount and setting the Slippage Tolerance, Click on Swap
                ->
                Confirm Swap to buy $SMRAT
              </div>
              <div class="image-section content-section multi-image">
                <img class="w-45" :src="require('@/assets/images/guide/Picture9.png')">
                <img class="w-45" :src="require('@/assets/images/guide/Picture10.png')">
              </div>

              <div class="step-section">
                - Step 4: Confirm to buy SMRAT on the MetaMask extension
              </div>
              <div class="image-section">
                <img :src="require('@/assets/images/guide/Picture11.png')">
              </div>
              <div class="step-section">
                - Step 5: Now you can see the result here:
              </div>
              <div class="image-section content-section multi-image">
                <img class="w-45" :src="require('@/assets/images/guide/Picture12.png')">
                <img class="w-45" style="height: fit-content;"
                     :src="require('@/assets/images/guide/Picture13.png')">
              </div>
            </div>


            <div id="claim-bnb">

              <div class="main-title">
                Collect $BNB
              </div>

              <div class="step-section">
                - Back to the MoonRat Dapp to check your amount, the time you can claim BNB from the
                reward
                pool
              </div>
              <div class="image-section">
                <img :src="require('@/assets/images/guide/Picture14.png')">
              </div>

              <div class="step-section">
                <span class="text-color"> Sometimes, you can’t see your SMRAT information.</span>
              </div>

              <div class="content-section">
                <div>
                  <b>- Issue 1:</b> You have not connected your wallet (BSC network) yet
                </div>

                <div class="content-section">
                  Just click on Connect wallet and connect to your MetaMask or Trustwallet.
                </div>

                <div class="image-section">
                  <img :src="require('@/assets/images/guide/Picture15.png')">
                </div>

                <div>
                  <b>- Issue 2:</b> You connected your wallet, but you can’t see your $SMRAT balance
                </div>

                <div class="image-section">
                  <img :src="require('@/assets/images/guide/Picture16.png')">
                </div>
                <div class="content-section">
                  Try to change the network to BSC network
                </div>

                <div class="image-section">
                  <img :src="require('@/assets/images/guide/Picture17.png')">
                </div>
              </div>

              <!--                        <div class="step-section">-->
              <!--                            - Or you can view in here:-->
              <!--                        </div>-->

              <!--                        <div class="text-center mt-5">-->
              <!--                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/AOWih5FkNBg"-->
              <!--                                    title="YouTube video player" frameborder="0"-->
              <!--                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"-->
              <!--                                    allowfullscreen></iframe>-->
              <!--                        </div>-->

            </div>


          </div>
        </div>

        <el-input id="copy-value" v-model="sm" class="hidden-input"/>

      </div>

    </section>
  </div>

</template>

<script>
import Header from "@/components/Header";

export default {
  name: 'Guide',
  components: {
    Header
  },
  props: {},
  data() {
    return {
      sm: '0x68590a47578e5060a29fd99654f4556dbfa05d10',
      show_back_to_top: false
    }
  },
  methods: {
    onScroll() {

      // Get the current scroll position
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      if (currentScrollPosition < 30) {
        this.show_back_to_top = false
      } else {
        this.show_back_to_top = true
      }

    },
    copy() {
      var self = this
      const testingCodeToCopy = document.querySelector('#copy-value')
      testingCodeToCopy.setAttribute('type', 'text')
      testingCodeToCopy.select()

      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful'

        self.$notify({
          title: 'Success',
          message: 'Copied value ' + msg,
          type: 'success'
        })
      } catch (err) {
        self.$notify({
          title: 'Error',
          message: 'Oops, unable to copy',
          type: 'success'
        })
      }

      window.getSelection().removeAllRanges()
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>


</style>
