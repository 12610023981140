<template>
  <section id="how-to-buy">
    <div class="main-title">Transparency</div>

    <div>
      <div class="row" style="margin-left: 0px; margin-right: 0px">
        <div class="list-item-hover" v-scrollanimation>
          <a class="item-hover" href="https://www.certik.org/projects/moonratfinance" target="_blank">
            <div class="item-icon">
              <img :src="require('@/assets/images/certik-audit.png')" />
            </div>
            <div class="item-content">
              <div class="item-title">Certik Audit</div>
              <div class="item-desc">About Audit of SMRAT project</div>
            </div>
          </a>

          <a class="item-hover" href="https://github.com/TechRate/Smart-Contract-Audits/blob/main/MoonRat%20Finance.pdf" target="_blank">
            <div class="item-icon">
              <img :src="require('@/assets/images/techrate-audit.jpeg')" />
            </div>
            <div class="item-content">
              <div class="item-title">Techrate Audit</div>
              <div class="item-desc">About Audit of SMRAT project</div>
            </div>
          </a>

          <a class="item-hover" href="https://docs.moonrat.finance/liquidity-locks" target="_blank">
            <div class="item-icon">
              <i class="el-icon-edit-outline"></i>
            </div>
            <div class="item-content">
              <div class="item-title">Burn and Lock</div>
              <div class="item-desc">About logic of burn and lock</div>
            </div>
          </a>

          <a class="item-hover" href="https://bscscan.com/token/0x68590a47578e5060a29fd99654f4556dbfa05d10" target="_blank">
            <div class="item-icon">
              <img :src="require('@/assets/images/bscscan.png')" />
            </div>
            <div class="item-content">
              <div class="item-title">Contract</div>
              <div class="item-desc">Contract of SMRAT project</div>
            </div>
          </a>


        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Transparency",
  components: {},
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.before-enter {
  opacity: 0;
  transform: translateY(100px);
  transition: all 1s ease-out;
}

/*
  If the element intersects with the viewport, the before-enter class is added.
*/
.enter {
  opacity: 1;
  transform: translateY(0px);
}

</style>
